import { createStore } from 'vuex'

import { auth } from './modules/AuthStore'
import { errors } from './utils/ErrosStore'
import { table } from './utils/TableStore'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    errors,
    table
  }
})
