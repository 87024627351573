<template>
  <v-dialog v-model="show" width="450">
    <v-card>
      <div class=" d-flex flex-column align-center px-4 py-6">

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-x-circle text-red-darken-2 w-4 h-4">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>

        <p class=" text-h5">Are you sure ?</p>
        <div class=" mb-4 mt-2 text-center">
          <p class=" text-subtitle-2 line-height-1">Deletion is permanent and cannot be undone</p>
          <!-- <p class="text-subtitle-2 line-height-1">The process can not be undone</p> -->
        </div>

        <div>
          <v-btn color="grey-lighten-1" class=" text-white" @click="show = false">
            Cancel
          </v-btn>
          <v-btn color="red-darken-1" :loading="deleting" @click="deleteTarget" class=" ml-3" text="Delete"/>
        </div>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {api} from '@/config/api';

export default {
  emits: ["update:modelValue", 'deleted', "delete"],
  props: {
    route: {type: String, required: false},
    target: {type: [String, Number], required: false},
    modelValue: {type: Boolean, required: true}
  },
  data: () => ({
    deleting: false
  }),
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit("update:modelValue", val)
      }
    }
  },
  methods: {
    deleteTarget() {
      if (!this.route) {
        this.deleting = true
        return this.$emit("delete", () => this.deleting = false)
      }

      api.delete(this.route + `/${this.target}`)
          .then(() => {
            this.show = false
            this.$emit("deleted")
          })
          .finally(() => this.deleting = false)
    }
  }
}
</script>

