import {createRouter, createWebHashHistory} from 'vue-router'

import store from '@/store'

const routes = [
    {
        path: '/',
        name: "home",
        redirect: () => {
            if (store.getters['auth/isAuthenticated']) {
                let user = store.getters['auth/user']
                if (user?.username == 'admin') {
                    return {name: "dashboard"}
                }
                return {name: "mtt"}

            } else {
                return {name: "login"}
            }
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/SettingsView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/DashboardView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/activity-logs",
        name: "activity_logs",
        component: () => import("@/views/ActivityLogsView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/attendances",
        name: "attendances",
        component: () => import("@/views/AttendancesView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/similar-kids",
        name: "similar_kids",
        component: () => import("@/views/SimilarKids.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/attendance-logs",
        name: "attendance_logs",
        component: () => import("@/views/AttendanceLogsView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/error-logs",
        name: "error_logs",
        component: () => import("@/views/ErrorLogsVIew.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/mtt",
        name: "mtt",
        component: () => import("@/views/MttView.vue"),
        meta: {
            middleware: "auth"
        }
    },
    {
        path: "/mtt-users",
        name: "mtt_users",
        component: () => import("@/views/MttUsersView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/contact-details",
        name: "contact_details",
        component: () => import("@/views/ContactDetailsView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/SettingsView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/videos",
        name: "videos",
        component: () => import("@/views/VideosView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/providers",
        name: "providers",
        component: () => import("@/views/HomeView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: '/providers/:provider/devices',
        name: 'providers.devices',
        component: () => import("../views/ProviderDevicesView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/roles",
        name: "roles",
        component: () => import("@/views/RoleView.vue"),
        meta: {
            middleware: "auth",
            managerRoute: true,
            admin: true
        }
    },
    {
        path: "/users",
        name: "users",
        component: () => import("@/views/UsersView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/api-routes",
        name: "api_routes",
        component: () => import("@/views/ApiRoutesView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    },
    {
        path: "/devices/:device/logs",
        name: "devices.logs",
        component: () => import("@/views/DeviceLogsView.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/LoginView.vue"),
        meta: {
            layout: "auth",
            middleware: "guest",
        }
    },
    {
        path: "/telegram-groups",
        name: "telegram_groups",
        component: () => import("@/views/TelegramGroupsView.vue"),
        meta: {
            middleware: "auth",
            admin: true
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta?.middleware === 'auth' && !store.getters['auth/isAuthenticated']) {
        next(({name: "login"}))
    } else if (to.meta?.middleware === 'guest' && store.getters['auth/isAuthenticated']) {
        next({name: 'home'})
    } else {
        const user = store.getters['auth/user'];
        if (to.meta.admin === true && user?.username !== 'admin') {
            return next({name: 'home'})
        }
        return next()
    }
    // else if (to.meta?.managerRoute) {
    //     const user = store.getters['auth/user'];
    //     if (!user?.is_manager) {
    //         next({name: 'home'})
    //     } else {
    //         next()
    //     }
    // } else {
    //     next()
    // }
})

export default router
