import axios from "axios";
import store from "@/store";

const baseURL =process.env.VUE_APP_BACKEND_SERVER + "/dashboard"

const api = axios.create({
    baseURL: baseURL,
    // withCredentials: true,
    headers: {
        "Accept": "application/json",
        "Accept-Language": "ru"
        // "Access-Control-Allow-Origin": true,
        // 'Authorization': `Bearer ${store.getters['auth/token']}`
    }
})

api.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${store.getters['auth/token']}`
    return request
})

api.interceptors.response.use((res) => {
    store.dispatch("clearErrors")
    return res
}, (res) => {
    store.dispatch("clearErrors")
    const status = res.response?.status
    console.log(status)
    if ([400,422].includes(status) ) {
        console.log(res.response)
        const errors = prepareErrors(res.response)
        console.log(errors)
        store.dispatch("setErrors", errors)
    }
    if (status === 401) {
        store.commit("auth/logout")
    }

    return Promise.reject(res)
})

function prepareErrors(response) {
    let {detail: responseData = []} = response.data
    if (!(responseData instanceof Array)) return {}
    let errors = {}
    responseData.forEach((data) => {
        errors[data.loc[1]] = data.msg
    })
    return errors
}

export {api,baseURL}