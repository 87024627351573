import {api} from "@/config/api"
import Cookies from "js-cookie"

export const auth = {
    namespaced: true,
    state: {
        auth: {
            _token: "",
            user: {}
        }
    },
    mutations: {
        setToken(state, token) {
            state.auth._token = token
        },
        mutateAuth(state, authData) {
            const token = authData.token
            state.auth._token = token
            state.auth.user = authData.user
            if (token)
                Cookies.set("_token", token)
        },
        logout(state) {
            state.auth._token = ""
            state.auth.user = {}
            Cookies.remove("_token")
            window.location.href = "/"
        }
    },
    actions: {
        login({commit}, data) {
            return new Promise((resolve, reject) => {
                api.post("/login", data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then((response) => {
                        console.log(response)
                        commit("mutateAuth", response.data ?? {})
                        resolve()
                    })
                    .catch(({response}) => {
                        if (response?.status === 400) {
                            commit("mutateErrors",
                                {"username": response?.data?.detail},
                                {root: true}
                            )
                        }
                    })
                    .finally(reject)
            })
        },
        authenticate({commit}) {
            const _token = Cookies.get("_token")
            if (!_token) return false
            commit("setToken", _token)
            api.get("/user")
                .then(({data = {}}) => {
                    commit("mutateAuth", {token: _token, user: data.user})
                    return true
                })

            return false
        },
        logout({commit}) {
            commit("logout")
        }
    },
    getters: {
        user: ({auth: {user = {}}}) => user,
        token: ({auth: {_token}}) => _token,
        isAuthenticated: ({auth: {_token}}) => !!_token
    }
}