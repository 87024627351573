<template>
    <component :is="layout" v-if="layout">
        <router-view />
    </component>
</template>

<script>
import AppLayout from './layouts/AppLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
    components: { AppLayout },
    // data: () => ({
    //     layouts: {
    //         auth: AuthLayout,
    //         default: AppLayout
    //     }
    // }),
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        layout() {
            if (this.$route.meta.layout === 'auth' || !this.isAuthenticated) {
                return AuthLayout
            }
            return AppLayout
        }
    },
    methods: {
        ...mapActions('auth', ['authenticate'])
    },
    created() {
        if (!this.authenticate()) {
            this.$router.push({ name: "login" })
        }
    }
}
</script>