Object.set = function (target, source, values = {}) {
    for (const field in target) {
        let value = ""
        if (Object.hasOwn(source, field)) value = source[field]
        else if (Object.hasOwn(values, field)) value = values[field]
        target[field] = value
    }
}

Object.empty = (object) => {
    return Object.keys(object).length === 0
}