import {createApp} from 'vue'
import "@/assets/scss/app.scss"

import App from './App.vue'

import router from './router'
import store from './store'
import {vuetify} from "./config/vuetify"

import "@/config/pusher"
import "@/config/ymaps"
import "@/utils/ObjectHelpers"

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {components} from "@/components"

import VueTheMask from 'vue-the-mask';
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

const app = createApp(App)
    .use(store)
    .use(router)
    .use(vuetify)

app.use(VueTheMask)

app.use(Vue3Toasity)
app.component("v-json-pretty",VueJsonPretty)
components.forEach((component) => {
    app.component(component.name, component)
})

app.component("v-datepicker", VueDatePicker)

app.mount('#app')
