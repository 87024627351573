<template>
    <v-autocomplete dense density="compact" variant="outlined" v-bind="componentParams" single-line @update:search="search"
        v-model="value" />
</template>

<script>
import { api } from '@/config/api'
export default {
    name: "search-select",
    // inheritAttrs: false,
    emits: ["update:modelValue"],
    props: {
        options: { type: [Object, Array], default: () => [] },
        route: { type: String },
        itemText: { type: String },
        itemValue: { type: String },
        modelValue: { type: [Array, String, Object, null] },
        field: { type: String },
        type: { type: String },
        searchParams: { type: Object, default: () => ({}) },
        label: { type: String },
        placeholder: { type: String },
        multiple: { type: Boolean },
        returnObject: { type: Boolean }
    },
    data: () => ({
        optionsToSelect: [],
        loading: false
    }),
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit("update:modelValue", val)
            }
        },
        componentParams() {
            return {
                items: this.optionsToSelect,
                loading: this.loading,
                itemText: this.itemText,
                itemTitle: this.itemText,
                label: this.label,
                placeholder: this.placeholder,
                multiple: this.multiple,
                returnObject: this.returnObject
            }
        }
    },
    watch: {
        options(options) {
            this.optionsToSelect = options
        },
    },
    methods: {
        search(value) {
            if (!this.route && !this.type) return
            let searchRoute = this.route ?? "/search"

            let searchParams = Object.assign(this.searchParams, this.route ? { value } : { type: this.type, value })

            searchRoute += ("?" + new URLSearchParams(searchParams))
            this.loading = true
            api.get(searchRoute)
                .then(({ data: response }) => {
                    this.optionsToSelect = response.data;
                })
                .finally(() => { this.loading = false })
        }
    },
}
</script>