export const errors = {
    state: {
        errors: {}
    },
    mutations: {
        mutateErrors(state, errors) {
            state.errors = errors
        }
    },
    actions: {
        clearErrors({ commit }) {
            commit("mutateErrors", {})
        },
        setErrors({ commit }, errors) {
            commit("mutateErrors", errors ?? {})
        }
    },
    getters: {
        errors: ({ errors }) => errors
    }
}