export const table = {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        mutateItems(state, items) {
            state.items = items
        },
        mutateTableItem(state, cb) {
            cb(state.items)
        }
    },
    actions: {
        setTableItems({ commit }, items) {
            commit("mutateItems", items)
        },
        updateTableItem({ commit }, cb) {
            commit("mutateTableItem", cb)
        }
    },
    getters: {
        items: ({ items }) => items
    }
}