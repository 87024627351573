<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer">
      <div style="height: 56px;" class=" pa-4 border-b  text-h6 d-flex align-center">
        Gateway
      </div>
      <!--            <providers-list-component />-->
      <v-list :nav="true">

        <v-list-item class="text-h6" prepend-icon="mdi-finance" :to="{name: 'dashboard'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Statistika
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-database-clock" :to="{name: 'activity_logs'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Faollik loglari
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-account-multiple-check" :to="{name: 'attendances'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Yo'qlamalar
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-account-multiple" :to="{name: 'similar_kids'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            O'xshash bollar
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-database-clock" :to="{name: 'attendance_logs'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Yo'qlamala loglari
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-database-clock" :to="{name: 'error_logs'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Xatolik loglari
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-home-circle" :to="{name: 'mtt'}">
          <v-list-item-title class=" text-body-1">
            MTT
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-account-group" :to="{name: 'mtt_users'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            MTT foydalanuvchilari
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-account-group" :to="{name: 'users'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Foydalanuvchilar
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-account-group" :to="{name: 'telegram_groups'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Telegram grouppalar
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-api" :to="{name: 'api_routes'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            API routes
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-card-account-phone" :to="{name: 'contact_details'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Kontakt ma'lumotlari
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-youtube" :to="{name: 'videos'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Videolar
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text-h6" prepend-icon="mdi-cog" :to="{name: 'settings'}" v-if="user.username == 'admin'">
          <v-list-item-title class=" text-body-1">
            Sozlamalar
          </v-list-item-title>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar density="comfortable">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer/>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" icon="mdi-account" color="grey-darken-3"
                 density="comfortable"/>
        </template>

        <v-list class=" w-min-10">

          <v-list-item density="compact" class=" border-b text-right">
            <p class=" text-subtitle-1">{{ user.username }}</p>
            <p class="text-caption">{{ user.role }}</p>
          </v-list-item>

<!--          <v-list-item density="compact" :link="true" :to="{ name: 'settings' }" v-if="user.username == 'admin'">-->
<!--            <v-list-item-title class=" d-flex  align-center">-->
<!--              <v-icon icon="mdi-cog"></v-icon>-->
<!--              <p class=" ml-2">Settings</p>-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->


<!--          <v-list-item density="compact" :link="true" :to="{ name: 'users' }" v-if="user.username == 'admin'">-->
<!--            <v-list-item-title class=" d-flex  align-center">-->
<!--              <v-icon icon="mdi-account-group"></v-icon>-->
<!--              <p class=" ml-2">Users</p>-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->


<!--          <v-list-item density="compact" :link="true" :to="{ name: 'roles' }" v-if="user.username == 'admin'">-->
<!--            <v-list-item-title class=" d-flex  align-center">-->
<!--              <v-icon icon="mdi-shield-account"></v-icon>-->
<!--              <p class=" ml-2">Roles</p>-->
<!--            </v-list-item-title>-->
<!--          </v-list-item>-->

          <v-list-item density="compact" :link="true" @click="logout">
            <v-list-item-title class=" d-flex  align-center">
              <v-icon icon="mdi-logout"></v-icon>
              <p class=" ml-2">Log out</p>
            </v-list-item-title>
          </v-list-item>

        </v-list>
      </v-menu>

    </v-app-bar>

    <v-main>

      <div class=" pa-4 w-100 h-100 relative">
        <slot></slot>
      </div>
    </v-main>


  </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';


export default {
  data: () => ({
    drawer: null,
    devicesMapModal: false,
    showAlert: false
  }),
  // components: { ProvidersListComponent },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logout',])
  },
  mounted() {
    // socket.on("api-error", (data) => {
    //   toast.error(data.url, {
    //     toastClassName: "bg-red-lighten-5 text-red-accent-4",
    //     closeOnClick: false,
    //   })
    // })

  }
}
</script>

<style>
.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
</style>