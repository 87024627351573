<template>
  <div>

    <v-table density="compact">
      <thead>
      <tr>
        <th class="pa-2 valign-bottom w-max-min-content">№</th>
        <th :style="{ width: header.width ?? 'auto', textAlign: header.align ?? 'left' }" class="pa-2 valign-bottom"
            v-for="header in headers" :key="header.key">

          <div class=" mb-4">
            <slot :name="`${header.key}-filter`"></slot>
          </div>

          <p>{{ header.title }}</p>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in (data || items)" :key="i">
        <td  class=" pa-2">
          {{ ((options.page-1) * options.limit) + i+1}}
        </td>
        <td v-for="(header, h) in headers" :key="`${i}-${h}`" class=" pa-2">

          <div v-if="header.key === 'actions'" class=" d-flex justify-end" style="gap: .5rem;">
            <slot :name="header.key" v-bind="{ item }">
              <slot name="extra-actions" v-bind="{ item }"></slot>

              <v-btn v-if="isEditable(item)" color="primary" @click="$emit('edit', item)">
                <v-icon icon="mdi-pencil"/>
              </v-btn>

              <v-btn v-if="isDeletable(item)" color="red-darken-1" @click="toggleRemoveDialog(item.id)">
                <v-icon icon="mdi-delete"/>
              </v-btn>

            </slot>
          </div>

          <div v-else :style="{textAlign: header.align ?? 'left' }">
            <slot :name="header.key" v-bind="{ item }">
              {{ getItemValue(header.key, item) ?? "-" }}
            </slot>
          </div>

        </td>
      </tr>
      <tr v-if="items.length === 0 && !loading && (data && data.length === 0)">
        <td :colspan="headers?.length" class=" text-center pa-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-4">
            <path
                d="M389.8 125.2C363.7 88.1 320.7 64 272 64c-77.4 0-140.5 61-143.9 137.5c-.6 13-9 24.4-21.3 28.8C63.2 245.7 32 287.2 32 336c0 61.9 50.1 112 112 112H512c53 0 96-43 96-96c0-36.8-20.7-68.8-51.2-84.9c-13.4-7.1-20-22.5-15.8-37.1c2-6.9 3-14.3 3-22c0-44.2-35.8-80-80-80c-12.3 0-23.9 2.8-34.3 7.7c-14.1 6.7-30.9 2.3-39.9-10.5zM272 32c59.5 0 112.1 29.5 144 74.8C430.5 99.9 446.8 96 464 96c61.9 0 112 50.1 112 112c0 10.7-1.5 21-4.3 30.8C612.3 260.2 640 302.9 640 352c0 70.7-57.3 128-128 128H144C64.5 480 0 415.5 0 336c0-62.8 40.2-116.1 96.2-135.9C100.3 106.6 177.4 32 272 32zM244.7 212.7c6.2-6.2 16.4-6.2 22.6 0L320 265.4l52.7-52.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L342.6 288l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L320 310.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L297.4 288l-52.7-52.7c-6.2-6.2-6.2-16.4 0-22.6z"/>
          </svg>
          <p class=" text-h6">No data found</p>
        </td>
      </tr>
      </tbody>
    </v-table>
    <div class=" w-100 d-flex justify-center pa-6" v-if="loadingItems">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div class=" d-flex align-center justify-space-between mt-4">
      <div>
        <v-select :items="selectOptions" v-model="options.limit" variant="outlined" density="compact"
                  hide-details></v-select>
      </div>
      <v-pagination v-model="options.page" :length="pagesAmount" :total-visible="5"></v-pagination>
    </div>

    <remove-dialog v-model="removeDialog" :target="target" :route="route" @deleted="loadItems"/>

  </div>
</template>

<script>
import {api} from '@/config/api';
import RemoveDialog from '../app/Dialogs/RemoveDialogComponent.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "app-table",
  emits: ['edit', 'update:filter'],
  components: {RemoveDialog},
  props: {
    headers: {type: Array, required: true},
    route: {type: String},
    filter: {type: Object, default: () => ({})},
    data: {type: Array}
  },
  data: () => ({
    // items: [],
    selectOptions: [10, 25, 50, 100],
    loading: false,
    options: {
      limit: 10,
      page: 1
    },
    meta: {
      total: 0,
    },
    removeDialog: false,
    target: 0,
    loadingItems: false,
    initialized: false,
    routeContoller: null,
  }),
  computed: {
    ...mapGetters('table', ['items']),
    pagesAmount() {
      if (!this.meta.total || !this.options.limit) return 0
      return Math.ceil(this.meta.total / this.options.limit)
    }
  },
  methods: {
    ...mapActions('table', ['setTableItems']),
    toggleRemoveDialog(id) {
      this.removeDialog = true
      this.target = id
    },
    loadItems() {
      if (!this.initialized || !this.route) return
      if (this.routeContoller) this.routeContoller.abort()

      console.log("works")

      // this.setTableItems([])
      this.loadingItems = true

      let params = Object.assign({}, this.filter, this.options)
      let queryParams = {}
      for (let field in params) {
        if (params[field] || params[field] === 0) queryParams[field] = params[field]
      }

      localStorage.setItem(window.location.hash, JSON.stringify(queryParams))

      params = new URLSearchParams(queryParams)

      this.routeContoller = new AbortController()
      api.get(this.route + "?" + params, {signal: this.routeContoller.signal})
          .then(({data}) => {
            this.setTableItems(data?.items ?? [])
            for (const option in this.meta) {
              this.meta[option] = Object.hasOwn(data.meta ?? {}, option) ? data.meta[option] : this.meta[option]
            }
          })
          .finally(() => {
            this.loadingItems = false
            this.routeContoller = null
          })
    },
    getItemValue(key, item) {
      if (!item || !key) return ""
      if (typeof key == 'string') {
        key = key.split(".")
      }
      if (key.length === 0) return item
      let currentKey = key.shift()
      return this.getItemValue(key, item[currentKey])
    },
    isDeletable(item) {
      if (Object.hasOwn(item, 'deletable') && item.deletable != null) return item.deletable
      return true
    },
    isEditable(item) {
      if (Object.hasOwn(item, 'editable') && item.editable != null) return item.editable
      return true
    },
    setupTable() {
      // this.setTableItems([])
      this.initalized = false
      new Promise((resolve) => {
        let params = localStorage.getItem(window.location.hash)
        if (params) {
          params = JSON.parse(params)
          if (!Object.hasOwn(params, "page") || !params["page"]) {
            params["page"] = 1
          }

          if (!Object.hasOwn(params, "limit") || !params["limit"]) {
            params["limit"] = 10
          }

          Object.set(this.options, params)
          let filter = Object.assign({}, this.filter)
          Object.set(filter, params)
          this.$emit("update:filter", filter)
        }
        resolve()
      })
          .then(() => {
            this.initialized = true
            this.loadItems()
          })
    }
  },
  watch: {
    options: {
      handler() {
        this.loadItems()
      },
      deep: true
    },
    'options.limit'() {
      if (this.initialized)
        this.options.page === 1 ? this.loadItems() : this.options.page = 1
    },
    filter: {
      handler() {
        if (this.initialized)
          this.options.page === 1 ? this.loadItems() : this.options.page = 1
      },
      deep: true
    },
    route() {
      this.setupTable()
    }
  },
  mounted() {
    this.setupTable()
  }
}
</script>