<template>
    <div class=" mb-2 text-h6 text-grey-darken-3 d-flex align-center justify-space-between w-100 -z-1">
        <h3>{{ title }}</h3>

        <div class="d-flex align-center">
            <slot name="actions"></slot>
            <div v-if="back">
                <v-btn text="Back" color="grey" prepend-icon="mdi-arrow-left-thin" :to="back" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "page-header",
    props: {
        title: { type: String, required: true },
        back: { type: [String, Object] }
    }
}
</script>